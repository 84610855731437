import React from "react";
import { t } from "i18next";
import { ReverseButton } from "../button";
import { addToCart } from "src/utils";

const carouselStyle = `h-[700px] sm:h-[600px] text-white text-center`;
const customTextStyle = `text-white font-bold leading-[60px] tracking-widest`;
const customEventHighlight = `font-bold text-txt-primary leading-[30px] sm:leading-[80px] tracking-widest`;
const customEventDes = `max-md:text-sm text-white leading-[30px] sm:leading-[80px] tracking-widest sm:pt-0 pt-8`;

export const Event = (props: any) => {
  const { coupon } = props;
  if (!coupon || coupon.length === 0) {
    // Default coupon style
    return (
      <div
        className={`${carouselStyle} w-full bg-cover`}
        style={{
          backgroundImage: `url("/assets/event_no_coupon_bg.png"`,
        }}
      >
        <div className="h-full w-full drop-shadow-lg bg-black bg-opacity-70">
          <div className="h-full flex flex-row">
            <div className="w-full flex flex-col justify-center flex-[1_1_8%] border-r-[25px] border-primary">
              <h4 className={customTextStyle}>{t(`home.eventleftHeader1`)}</h4>
              <h4 className={customTextStyle}>{t(`home.eventleftHeader2`)}</h4>
              <h4 className={customTextStyle}>{t(`home.eventleftHeader3`)}</h4>
            </div>
            <div className="h-full w-full flex flex-col justify-center flex-2 pl-20 text-left">
              <h4 className={customEventDes}>
                {t(`home.eventNoCouponHeader1`)}
              </h4>
              <h4 className={customEventDes}>
                {t(`home.eventNoCouponHeader2`)}
              </h4>
              <h4 className={`text-[12px] ${customEventDes}`}>
                {t(`home.eventRightHeader3`)}
              </h4>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    coupon &&
    coupon.map((event: any, i: number) => {
      return (
        <div key={i}>
          <div
            className={`${carouselStyle} w-full bg-cover object-cover`}
            style={{
              backgroundImage: event.banner
                ? `url(${event.banner})`
                : `url("/assets/event_no_coupon_bg.png"`,
            }}
          >
            <div className="h-full w-full drop-shadow-lg bg-black bg-opacity-70">
              <div className="h-full flex max-md:flex-col md:flex-row">
                <div className="w-full flex flex-col justify-center flex-[1_1_8%] md:border-r-[25px] max-md:border-b-[5px] border-primary">
                  <h4 className={customTextStyle}>
                    {t(`home.eventleftHeader1`)}
                  </h4>
                  <h4 className={customTextStyle}>
                    {t(`home.eventleftHeader2`)}
                  </h4>
                  <h4 className={customTextStyle}>
                    {t(`home.eventleftHeader3`)}
                  </h4>
                </div>
                <div className="h-full w-full flex flex-col md:justify-center max-md:pt-5 flex-2 max-md:px-5 md:pl-20 text-left">
                  <h4
                    className={`text-[12px] ${customEventDes} text-txt-primary tracking-[5px]`}
                  >
                    {event.name}
                  </h4>
                  <h4 className={customEventDes}>
                    {t(`home.eventRightBreakHeader1`)}
                    <span className={customEventHighlight}>
                      {event.available_stocks}
                    </span>
                    {t(`home.eventRightBreakHeader2`)}
                  </h4>
                  <h4 className={customEventDes}>
                    {t(`home.eventRightBreakHeader3`)}
                    <span className={customEventHighlight}>
                      ${event.amount}
                    </span>
                    {t(`home.eventRightBreakHeader4`)}
                    <span className={customEventHighlight}>
                      ${event.face_value}
                    </span>
                    {t(`home.eventRightBreakHeader5`, {
                      name: event.merchant_name,
                    })}
                  </h4>
                  <h4 className={`text-[12px] ${customEventDes}`}>
                    {t(`home.eventRightHeader3`)}
                  </h4>
                </div>
              </div>
            </div>
          </div>
          <ReverseButton
            buttonText={`home.buyNow`}
            customStyle={`px-6 py-4 absolute z-40 bottom-[0px] right-[0px]`}
            handleAction={() => addToCart(event["_id"], true, event)}
            type="coupon"
          />
        </div>
      );
    })
  );
};

import React from "react";

const { Modal } = require("antd");

export const CustomModal = (props: any) => {
  const {
    children,
    centered,
    open,
    content,
    title,
    header,
    width,
    height,
    footer,
    okText,
    cancelText,
    handleCancel,
    onCallback,
  } = props;

  const handleOk = (e: any) => {
    onCallback && onCallback();
  };

  return (
    <Modal
      centered={centered}
      visible={open}
      title={title}
      okText={okText}
      cancelText={cancelText}
      onOk={(e: any) => handleOk(e)}
      onCancel={handleCancel}
      header={header}
      width={width}
      height={height}
      footer={footer}
    >
      {content}
      {children}
    </Modal>
  );
};

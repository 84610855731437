import {
  openSuccessNotification,
  openWarningNotification,
} from "src/components";
import { getLocalStorage, setLocalStorage } from "src/utils";
import { history } from "src/stores";
import { t } from "i18next";

export const addToCart = (id: string, checkout = false, item: any = null) => {
  if (!item) return;

  // Check available stocks
  if (!item.available_stocks) {
    openWarningNotification(t("messages.couponOutOfStock"));
    return;
  }

  let checkoutItems = getLocalStorage("checkoutItems");
  const exist: any = checkoutItems.findIndex((item: any) => item._id === id);
  // if exist +1 otherwise create new object
  if (exist && exist > -1) {
    checkoutItems[exist].quantity += 1;
  }
  exist && checkoutItems.push({ _id: id, quantity: 1 });
  setLocalStorage("checkoutItems", checkoutItems);
  // update the shopping cart
  openSuccessNotification(t("coupon.addToCart"));
  checkout && history.push("/checkout");
};

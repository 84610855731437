import React, { useEffect, useState } from "react";
import {
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import * as Configs from "../../config";
import { useTranslation } from "react-i18next";
import {
  LoadingIcon,
  openWarningNotification,
  ReverseButton,
} from "src/components";
import { history } from "src/stores";

export const CheckoutForm = (props: any) => {
  const { t } = useTranslation();
  const { email, checkAvailableCoupons, stripeOrder, isLoading, setIsLoading } =
    props;
  const stripe = useStripe();
  const elements = useElements();
  const [message, setMessage] = useState<string | null>(null);

  useEffect(() => {
    if (!stripe) {
      setMessage("");
      return;
    }
    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      return;
    }
    stripe
      .retrievePaymentIntent(clientSecret)
      .then(({ paymentIntent }: any) => {
        switch (paymentIntent.status) {
          case "succeeded":
            setMessage(t("payment.stripe.succeeded"));
            break;
          case "processing":
            setMessage(t("payment.stripe.processing"));
            break;
          case "requires_payment_method":
            setMessage(t("payment.stripe.failed"));
            break;
          default:
            setMessage(t("payment.stripe.500"));
            break;
        }
      });
  }, [stripe, t]);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (!stripe || !elements) {
      return;
    }
    setIsLoading(true);
    // Check Available Coupons with transaction ID
    const hasCoupons = await checkAvailableCoupons(stripeOrder._id);
    if (!hasCoupons["success"]) {
      setIsLoading(false);
      openWarningNotification(t("messages.couponOutOfStock"));
      history.push("/checkout");
      return;
    }

    // Confirm payment
    const payment: any = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${Configs.BASE_URL}/status?email=${email}&orderId=${stripeOrder.orderId}&method=stripe`,
      },
    });
    const { error } = payment;
    const { type } = error;
    if (type === "card_error" || type === "validation_error") {
      setMessage(error.message);
    } else {
      setMessage(t("payment.stripe.500"));
    }

    setIsLoading(false);
  };

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <PaymentElement id="payment-element" />
      <ReverseButton
        id="submit"
        fullWidth={true}
        disabled={
          isLoading || !stripe || !elements || !stripeOrder.clientSecret
        }
        customStyle={`mt-8 mb-1 pl-0 px-[5px] py-[8px] sm:text-sm focus:ring-1 sm:text-[12px] tracking-wider text-white bg-primary rounded text-center`}
      >
        {isLoading ? <LoadingIcon /> : t("button.payNow")}
      </ReverseButton>

      {/* Error / Success Messages */}
      {message && (
        <div id="payment-message">
          <p className="mt-m note">{message}</p>
        </div>
      )}
    </form>
  );
};

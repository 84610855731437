import React, { useCallback, useEffect, useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { LoadingIcon, ReverseButton } from "src/components";
import { history } from "src/stores";
import { t } from "i18next";
import { loadStripe } from "@stripe/stripe-js";
import * as Configs from "src/config";
import { CheckoutForm } from "./checkout-form";

export const StripeForm = (props: any) => {
  const { email, transaction, createPaymentOrder, checkAvailableCoupons } =
    props;
  const [clientSecret, setClientSecret] = useState(null);
  const stripePromise = loadStripe(Configs.STRIPE_CONFIG.pk);
  const [stripeOrder, setStripeOrder] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const getOrderSecret = useCallback(async () => {
    setIsLoading(true);
    const result = await createPaymentOrder(transaction);
    result && setClientSecret(result["clientSecret"]);
    setStripeOrder(result);
    setIsLoading(false);
    return;
  }, [transaction, createPaymentOrder, setClientSecret, setStripeOrder]);

  useEffect(() => {
    if (!transaction || !transaction["amount"]) return;
    getOrderSecret();
  }, [transaction, getOrderSecret]);

  const appearance = {
    theme: "night",
  };

  const options: any = {
    clientSecret,
    appearance,
  };

  return (
    <div className="w-[auto] sm:w-[550px] h-auto m-auto">
      <div className="p-12">
        <h4 className="text-[16px] mb-2 text-center tracking-[18px]">
          {t("payment.stripe")}
        </h4>

        {!clientSecret && (
          <div className="flex justify-center items-center">
            <LoadingIcon />
          </div>
        )}

        {clientSecret && (
          <Elements options={options} stripe={stripePromise}>
            <CheckoutForm
              email={email}
              stripeOrder={stripeOrder}
              checkAvailableCoupons={checkAvailableCoupons}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
            />
            <ReverseButton
              handleAction={() => history.goBack()}
              fullWidth={true}
              buttonText={`button.goBack`}
              customStyle={`mt-3.5 pl-0 px-[5px] py-[8px] sm:text-sm focus:ring-1 sm:text-[12px] tracking-wider text-primary bg-white border border-primary`}
            />
          </Elements>
        )}
      </div>
    </div>
  );
};

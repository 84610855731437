export const tcTranslation = {
  messages: {
    pleaseWait: "敬請期待",
    warning: "稍等一下",
    error: "抱歉",
    success: "恭喜",
    updateProfiletFailed: "更新個人檔案失敗",
    requiredFieldMissing: "未完成必填項目",
    incorrectEmailFormat: "請輸入有效電郵",
    updateProfileSuccess: "更新成功！",
    noCouponFound: "未有找到優惠劵",
    couponOutOfStock: "優惠劵已售缺罄",
    pleaseLoginToContinue: "請登入！",
    cartIsEmpty: "您的購物車目前是空的。 ",
    pleaseReconnectWallet: "請重新連接錢包",
  },
  home: {
    eventleftHeader1: "RE:VERSE",
    eventleftHeader2: "IN THE",
    eventleftHeader3: "METAVERSE",
    eventNoCouponHeader1: "先到先得 每天推出特別優惠券",
    eventNoCouponHeader2: "下一款特別優惠券即將推出",
    eventRightBreakHeader1: "先到先得 每天剩餘",
    eventRightBreakHeader2: "張",
    eventRightBreakHeader3: "以",
    eventRightBreakHeader4: "購買原價",
    eventRightBreakHeader5: "的{{name}}優惠券",
    eventRightHeader3: "推薦朋友購買即能獲得更多優惠",
    buyNow: "立即購買",
    hotItems: "熱門產品",
    noHotItems: "沒有熱門產品",
    allItems: "所有產品",
    noItems: "敬請期待產品更新",
    addToCart: "加到購物車",
    alreadyPurchased: "人已購買",
  },
  footer: {
    copyright: "Copyright © 2023 ReverseDAO Tech Limited, ALL RIGHTS RESERVED",
    aboutUs: "關於我們",
    whitepaper: "白皮書",
    news: "最新消息",
    followUs: "關注我們以獲得最新優惠",
  },
  coupon: {
    tnc: "使用細則",
    expiryDate: "到期日",
    addToCart: "成功加入購物車!",
    couponDetails: "使用優惠券",
  },
  showDiscountText:
    "我正在以 {{amount}} 購買 {{face_value}} 的 {{name}}，如果你使用我的邀請碼:{{referral_code}}，我們將能獲得更多優惠！",
  noDiscountText:
    "我正在以 {{amount}} 購買 {{name}}，如果你使用我的邀請碼: {{referral_code}}，我們將能獲得更多優惠！",
  showDiscountTextWithoutReferral:
    "我正在以 {{amount}} 購買 {{face_value}} 的 {{name}}！",
  noDiscountTextWithoutReferral: "我正在以 {{amount}} 購買 {{name}}！",
  redeemNow: "立即使用",
  profile: {
    username: "名字",
    countryCode: "區號",
    phoneNumber: "電話號碼",
    email: "電郵地址",
  },
  status: {
    success: "付款成功",
    error: "付款失敗",
    email:
      "我們已向您發送電郵通知。如果您沒有設置電郵，請稍等 5 - 10 分鐘，優惠券會自動發送到您的錢包。",
  },
  button: {
    update: "更新",
    payNow: "付款",
    connectToPayWithBUSD: "連接錢包以BUSDT付款",
    connectToPayWithUSDT: "連接錢包以USDT付款",
    connectToPayWithBNB: "連接錢包以BNB付款",
    connectToPayWithETH: "連接錢包以ETH付款",
    connectToPayWithREVD: "連接錢包以REVD付款",
    connectToPayWithBRE: "連接錢包以tETH付款",
    pleaseSelectToken: "連接錢包以代幣付款",
    payWithBUSD: "以BUSD付款",
    payWithBNB: "以BNB付款",
    payWithUSDT: "以USDT付款",
    payWithETH: "以ETH付款",
    payWithREVD: "以REVD付款",
    payWithBRE: "以tETH付款",
    goBack: "返回",
    goToWallet: "返回錢包",
    goToHome: "返回主頁",
    goToCheckout: "返回結帳頁面",
    confirm: "確定",
    cancel: "取消",
  },
  payment: {
    stripe: "以STRIPE付款",
    crypto: "以加密貨幣付款",
    cvv: "CVV",
    error: {
      approveOrderError: "確認訂單失敗",
    },
    invaildNetwork: "無效網絡鏈",
  },
  notification: {
    warning: {
      walletNetworkError: "暫時未接受此網絡鏈",
      notSelectToken: "請選擇網絡鏈",
      approveCancelled: "確認請求取消",
      userCancelledRequest: "轉換網絡鏈請求取消",
      transferCancelled: "付款請求取消",
      pleaseTryAgainLater: "請稍候再試",
      userDeniedRequest: "用戶拒絕請求",
      insufficientBalance: "餘額不足",
      disable: "暫時無法進行交易，請重新嘗試",
    },
    success: {
      emailNotification:
        "你已成功付款，我們正在向你發送優惠券，請檢查郵箱獲取最新產品訊息",
      updatePayment: "更新付款成功！",
    },
    error: {
      invaildPaymentToken: "無效付款代幣",
      updatePayment: "更新付款失敗！",
      notSupportedPaymentNetwork: "無效網絡鏈",
      failedToSubmit: "傳送請求失敗",
    },
  },
  header: {
    hk: "香港",
    explore: "探索",
    wallet: "錢包",
    language: "English",
    editProfile: "編輯個人頁面",
    referral: "推薦計劃",
    login: "登入",
    logout: "登出",
  },
  login: {
    introA: "ACCESS",
    introB: "TO",
    introC: "RE:VERSE",
    login: "登入",
    mobile: "手機登錄",
    email: "電郵登錄",
    successLogin: "登入成功",
    successFailed: "登入失敗，請稍後再試。",
    mobileForm: {
      postCode: "區號",
      phoneNumber: "電話號碼",
      twoFa: "驗證碼",
      twoFaBtnName: "獲取",
      email: "電郵",
      codeSent: "已發送驗證碼",
      failedToSend: "發送驗證碼失敗",
    },
    error: {
      postcodeLength: "區號不可空白",
      phoneLength: "電話號碼不可空白",
      twoFaLength: "驗證碼不可空白",
      emailLength: "電郵不可空白",
      emailFormat: "請輸入有效電郵",
    },
    success: "登入成功",
    failed: "登入失敗",
  },
  wallet: {
    title: "我的錢包",
    menus: {
      all: "全部",
      notUse: "未使用",
      almostExpire: "即將到期",
      used: "已使用",
      expired: "已過期",
    },
    usedAll: "已全使用",
    expireTitle: "到期日",
    left: "剩餘 {{remaining}} 張",
    noItem: "你沒有購買任何優惠券",
    clickToCopy: "點擊以複製兌換碼",
    confirmIsUsed: "確認此兌換券已經換兌嗎？",
    balance: "餘額",
  },
  checkout: {
    title: "付款明細",
    totalTitle: "總額",
    paymentTitle: "付款方式",
  },
  referral: {
    title: "我的推薦碼",
    form: {
      referralCode: "邀請人推薦碼",
      codeConfirm: "確定",
      success: "邀請碼使用成功",
      error: {
        empty: "邀請碼不可空",
        self: "不可使用自己的邀請碼",
      },
      used: "你已使用此邀請碼:",
    },
    errors: {
      default: "該邀請碼無效！",
      used: "你已使用邀請碼！",
    },
    copied: "成功複制",
    subtitle: "推薦福利",
    promo1:
      "關注我們以獲得最新優惠關注我們以獲得最新優惠關注我們以獲得最新優惠",
    promo2: "邀請人購買優惠券，你即可獲得 $REVD 代幣",
  },
  redPocket: {
    modalTitle: "恭喜獲得紅包獎賞！",
    modalSubtitle: "你已獲得",
    cannotOpen: "無法獲取紅包或已經領取。",
  },
  // From Web3
  "ERR:Modal closed by user": "請重新連接錢包。",
  "Modal closed by user": "請重新連接錢包。",
  metamask_desc: "從 Metamask 連接您的錢包",
  wallet_desc: "從其他錢包連接",
};

import React, { useEffect, useMemo, useState } from "react";
import { Container } from "src/components";
import { Products } from "./products";
import { history } from "src/stores";
import { useCouponStore } from "../../stores";
import * as Configs from "../../config";

import { t } from "i18next";
import { getLocalStorage } from "src/utils";

const payments: any = [
  {
    image: "/assets/icons/payment/stripe.png",
    method: "stripe",
    page: "/payment/stripe",
    width: "w-8",
    disabled: false,
  },
  {
    image: "/assets/icons/payment/paypal.png",
    method: "paypal",
    page: "/payment/paypal",
    width: "w-10",
    disabled: true,
  },
  {
    image: "/assets/icons/payment/blockchain.png",
    method: "crypto",
    page: "/payment/crypto",
    width: "w-6",
    disabled: false,
  },
];

export const Checkout = (props: any) => {
  const [checkoutData, setCheckoutData] = useState<any>([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [coupons, setCoupons] = useState<any>([]);

  const { getCoupons } = useCouponStore();

  useEffect(() => {
    const updateCheckoutItems = () => {
      setTimeout(() => {
        let checkoutItems: any = getLocalStorage("checkoutItems");
        //When identify cart is empty, redirect to "/"
        if (checkoutItems.length === 0) {
          setCheckoutData([]);
          return history.push("/");
        }

        if (checkoutItems.length > 0 && coupons.length > 0) {
          checkoutItems = checkoutItems.map((checkoutItem: any) => {
            const matchItems = coupons.find(
              (item: any) => item["_id"] === checkoutItem["_id"]
            );
            return {
              ...matchItems,
              quantity: checkoutItem["quantity"],
            };
          });
          setCheckoutData(checkoutItems);
        }
      }, 100);
    };
    window.addEventListener("storage", updateCheckoutItems);
    return () => {
      window.removeEventListener("storage", updateCheckoutItems);
    };
  }, [coupons, setCheckoutData]);

  //   init the data from the localStorage
  useEffect(() => {
    getCoupons("").then((result) => {
      let checkoutItems = getLocalStorage("checkoutItems");
      if (checkoutItems) {
        checkoutItems = checkoutItems.map((checkoutItem: any) => {
          const matchItems = result.find(
            (item: any) => item["_id"] === checkoutItem["_id"]
          );
          return {
            ...matchItems,
            quantity: checkoutItem["quantity"],
          };
        });
        setCheckoutData(checkoutItems);
        setCoupons(result);
      }
    });
  }, [getCoupons, setCoupons]);

  useEffect(() => {
    let accPrice = 0;
    checkoutData &&
      checkoutData.length > 0 &&
      checkoutData.map((data: any, index: number) => {
        const { amount: price, quantity = 0 } = data;
        accPrice += quantity * parseInt(price);
        return setTotalPrice(accPrice);
      });
  }, [checkoutData, setTotalPrice]);

  const handlePayment = (props: any) => {
    const { method, page = "/", disabled } = props;
    if (!method || disabled) return;
    switch (method) {
      case "paypal":
        return false;
      case "crypto":
      case "stripe":
        //Create Payment Order
        const couponData = {
          amount: totalPrice,
          currency: "HKD",
          method: method,
          checkout_items: [...checkoutData],
        };
        history.push({ pathname: `${page}`, state: { ...couponData } });
        break;
    }
  };

  const disabledStriptButton = useMemo(() => {
    let disabled = false;
    for (let data of checkoutData) {
      if (Configs.acceptMechantId.indexOf(data.merchant_id) === 0) {
        return true;
      }
    }
    return disabled;
  }, [checkoutData]);

  return (
    <Container>
      <div className="flex flex-col items-center px-10 pt-20">
        <h4 className="mb-10 tracking-widest">{t("checkout.title")}</h4>

        <Products
          totalPrice={totalPrice}
          checkoutData={checkoutData}
          setCheckoutData={setCheckoutData}
        />

        {/* Payment Methods */}
        <h4 className="mt-10 tracking-widest">{t("checkout.paymentTitle")}</h4>
        <div className="flex flex-row w-[30%] mt-10 justify-center">
          {payments &&
            payments.length > 0 &&
            payments.map(
              (
                payment: {
                  image: string;
                  width: string;
                  method: string;
                  disabled: Boolean;
                },
                index: number
              ) => {
                const { image, width, method, disabled } = payment;
                let buttonDisabled = disabled;
                if (method === "stripe" && disabledStriptButton) {
                  buttonDisabled = disabledStriptButton;
                }
                return (
                  <div
                    key={index}
                    onClick={() => handlePayment(payment)}
                    className={`flex-1 flex justify-center ${
                      buttonDisabled
                        ? `cursor-not-allowed opacity-60`
                        : `cursor-pointer`
                    }`}
                  >
                    <img
                      alt="payment"
                      className={`${width} object-contain mx-10 `}
                      src={image}
                    />
                  </div>
                );
              }
            )}
        </div>
      </div>
    </Container>
  );
};

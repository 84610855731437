import {
  EthereumClient,
  modalConnectors,
  walletConnectProvider,
} from "@web3modal/ethereum";
import { configureChains, createClient } from "wagmi"; //chain
import { mainnet, bsc, goerli, bscTestnet } from "@wagmi/core/chains"; // bsc, bscTestnet
import * as Configs from "src/config";
import { openErrorNotification } from "src/components";

require("dotenv").config();

export const ercToken = require("./abi/erc20Token.json");
export const apiUrl = process.env.REACT_APP_API_URL || "http://localhost:7009";

export const BASE_URL =
  process.env.REACT_APP_BASE_URL || "http://localhost:3000";

export const STRIPE_CONFIG = {
  pk: process.env.REACT_APP_STRIPE_PK || "",
};

export const cryptoConfig = {
  receiverWalletAddress: process.env.REACT_APP_RECEIVER_WALLET_ADDRESS,
};
export const NETWORK = process.env.REACT_APP_NETWORK;

export const infuraId = process.env.REACT_APP_INFURA_ID || "";

export const acceptMechantId = [process.env.REACT_APP_MERCHANT_ID];

export const supportingChain: any = {
  mainnet: {
    homestead: [
      //erc
      { name: "ETH", address: null, dp: 18 },
      {
        name: "USDT",
        address: "0xdAC17F958D2ee523a2206206994597C13D831ec7",
        dp: 6,
      },
    ],
    bsc: [
      {
        name: "BNB",
        address: null,
        dp: 18,
      },
      {
        name: "REVD",
        address: "0x30801df495BfeD13a8FE1049Bf75f6880ea48e66",
        dp: 18,
      },
      {
        name: "USDT",
        address: "0x524bC91Dc82d6b90EF29F76A3ECAaBAffFD490Bc",
        dp: 6,
      },
      {
        name: "BUSD",
        address: "0x55d398326f99059ff775485246999027b3197955",
        dp: 18,
      },
    ],
  },
  testnet: {
    "bsc-testnet": [
      {
        name: "USDT",
        address: "0x337610d27c682e347c9cd60bd4b3b107c9d34ddd",
        dp: 18,
      },
      {
        name: "BUSD",
        address: "0xeD24FC36d5Ee211Ea25A80239Fb8C4Cfd80f12Ee",
        dp: 18,
      },
    ],
    goerli: [
      {
        name: "ETH",
        address: null,
        dp: 18,
      },
      {
        name: "BRE", //tETH
        address: "0x2188F9606a580Ee13F37267559279d025408E8e0",
        dp: 18,
      },
      {
        name: "USDT",
        address: "0xE318Af23c7D8840AD23E78B8A11F6251112C25D8",
        dp: 18,
      },
    ],
  },
};

// Web3 Modal
export const projectId: string =
  process.env.REACT_APP_WAGMI_ID || "6932cedd41f15536358bf72594523e7b";

export const chains: any =
  process.env.REACT_APP_NETWORK === "mainnet"
    ? [mainnet, bsc]
    : [goerli, bscTestnet];

export const supportedTokens =
  process.env.REACT_APP_NETWORK === "mainnet"
    ? supportingChain["mainnet"]
    : supportingChain["testnet"];

const { provider } = configureChains(chains, [
  walletConnectProvider({ projectId: projectId }),
]);

export const wagmiClient = createClient({
  autoConnect: true,
  connectors: modalConnectors({
    appName: "web3Modal",
    version: "1",
    projectId: projectId,
    chains,
  }),
  provider,
});

// Web3Modal Ethereum Client
export const ethereumClient = new EthereumClient(wagmiClient, chains);

export const acceptToken = [
  "BNB",
  "BRE",
  "BUSD",
  "ETH",
  "tUSDE",
  "USDT",
  "BUSD",
  "REVD",
];

export const getChainName: any = (id: number) => {
  switch (id) {
    case 1:
      return "erc";
    case 56:
      return "bsc";
    case 97:
      return "bsc-testnet";
    case 5:
      return "goerli";
    default:
      break;
  }
};

export const getChain: any = (network: any) => {
  switch (
    //1, 97, 56, 137
    network
  ) {
    case "erc":
      return {
        chainId: 1,
        name: "erc", // "erc",
      };
    case "homestead":
      return {
        chainId: 1,
        name: "erc", // "erc",
      };
    case "bsc":
      return {
        chainId: 56,
        name: "bsc",
      };
    case "bsc-testnet":
      return { chainId: 97, name: "bsc-testnet" };
    case "goerli":
      return {
        chainId: 5,
        name: "goerli",
      };
  }
};

export const getCurrency: any = (network: any, token: any) => {
  switch (network) {
    case "bsc":
      return `${token}_BSC`;
    case "homestead":
    case "erc":
    case "mainnet":
      return `${token}_ERC`;
    case "bsc-testnet":
      return `${token === "BRE" ? "TETH_GOERLI" : `${token}_TBSC`}`;
    case "goerli":
      return `${token === "BRE" ? "TETH_GOERLI" : `${token}_GOERLI`}`;
  }
};

export const getTokenPair: any = (tokenName: string, network: string) => {
  let formatedNetwork = network;
  switch (network) {
    case "bsc-testnet":
      formatedNetwork = "TBSC";
      break;
    case "bsc":
      formatedNetwork = "BSC";
      break;
    case "homestead":
      formatedNetwork = "ERC";
      break;
  }
  return `${tokenName.toUpperCase()}_${formatedNetwork.toUpperCase()}`;
};

export const validate = (
  chainId: any,
  depositToken: string,
  chainName: any,
  t?: any
) => {
  const network: any = getChain(chainName);
  if (!network) return false;
  if (chainId !== network.chainId) {
    openErrorNotification(
      t("notification.warning.walletNetworkError", { network: Configs.NETWORK })
    );
    return false;
  }
  if (!depositToken) {
    openErrorNotification(t("notification.warning.notSelectToken"));
    return false;
  }
  return true;
};

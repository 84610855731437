import { t } from "i18next";
import React, { useMemo, useState } from "react";
import { CustomModal } from "../modal";
import { openSuccessNotification } from "../notification";
import QRCode from "react-qr-code";
import { useCouponStore } from "src/stores";
const { Popconfirm } = require("antd");

const inputStyle =
  "font-bold text-black block w-full px-3 py-2 bg-white border text-sm placeholder-black border-[#76C14E] focus:outline-none cursor-pointer";

export const ShowCouponModal = (props: any) => {
  const [qrId, setQrId] = useState(null);
  const { openModal, setOpenModal, coupon } = props;
  const { redeemCoupon } = useCouponStore();
  const [loading, setLoading] = useState(false);

  const copyToClipboard = (value: string) => {
    const element = document.createElement("textarea");
    element.value = value;
    document.body.appendChild(element);
    element.select();
    document.execCommand("copy");
    openSuccessNotification(t("referral.copied"));
    document.body.removeChild(element);
  };

  const confirm = async (e: React.MouseEvent<HTMLElement>, item: any) => {
    if (loading) return;
    setLoading(true);
    await redeemCoupon({
      coupon_code: item["coupon_code"],
      coupon_supply_id: item["_id"],
    });
    setLoading(false);
    window.location.reload();
  };

  const couponSupplies = useMemo(() => {
    if (!coupon || !coupon.coupon_supplies) return [];
    return coupon["coupon_supplies"].sort(
      (a: any, b: any) => a.is_used - b.is_used
    );
  }, [coupon]);

  return (
    <CustomModal
      open={openModal}
      okText={t(`button.confirm`)}
      cancelText={t(`button.cancel`)}
      className="text-primary"
      onCallback={() => setOpenModal(false)}
      handleCancel={() => setOpenModal(false)}
      title={t(`coupon.couponDetails`)}
    >
      <div>
        <p className="pb-5">{t("wallet.clickToCopy")}</p>
        {/* Redeem Coupon Defails Infomation */}
        {couponSupplies.map((item: any, i: number) => {
          const isUsed = item.is_used;
          return (
            <div key={i} className="pb-5 relative">
              <input
                className={`${inputStyle} ${
                  isUsed && "text-[#9d9d9d] cursor-not-allowed"
                }`}
                value={item && item.coupon_code}
                readOnly
                onClick={() => !isUsed && copyToClipboard(item.coupon_code)}
              />
              {/* QR Code */}
              {!isUsed && (
                <img
                  src="/assets/icons/qr.png"
                  alt="qr-icon"
                  className="w-6 h-6 absolute right-[7px] top-[7px] cursor-pointer"
                  onClick={() => {
                    if (qrId === item["_id"]) return setQrId(null);
                    setQrId(item["_id"]);
                  }}
                />
              )}

              {/* Is used coupon */}
              {!isUsed && (
                <Popconfirm
                  title={t("wallet.confirmIsUsed")}
                  description="Are you sure to delete this task?"
                  onConfirm={(e: any) => confirm(e, item)}
                  onCancel={() => null}
                  okText={t(`button.confirm`)}
                  cancelText={t(`button.cancel`)}
                >
                  <p className="text-secondary cursor-pointer text-[12px] mt-1">
                    {t("wallet.confirmIsUsed")}
                  </p>
                </Popconfirm>
              )}

              {/* Used coupon */}
              {isUsed && (
                <p className="text-[#EF637C] cursor-pointer text-[12px] mt-1">
                  {t("wallet.menus.used")}
                </p>
              )}

              {/* QR Code Icon */}
              {qrId === item["_id"] && (
                <div className="pt-5 pb-5 w-[100%] text-center">
                  <QRCode value={item.coupon_code} style={{ margin: "auto" }} />
                </div>
              )}
            </div>
          );
        })}
      </div>
    </CustomModal>
  );
};

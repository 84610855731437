import React, { useState } from "react";
import { t } from "i18next";
import { MobileLoginForm } from "./mobile-login-form";
import { EmailLoginForm } from "./email-login-form";
import {
  LoadingIcon,
  openWarningNotification,
  openSuccessNotification,
  ReverseButton,
  openErrorNotification,
} from "../../components";
import { validateEmail } from "../../utils";
import { history, useAuthStore } from "../../stores";

const backgroundImgStyle = `h-[600px] text-white text-center`;
const customTextStyle = `text-white font-bold leading-[60px] tracking-widest`;
const loginMethodTextStyle = `text-white font-bold leading-[60px] tracking-wider cursor-pointer`;

export const LoginSection = (props: any) => {
  // 1 = mobile, 2 = email
  const [method, setMethod] = useState(0);
  const [postCode, setPostCode] = useState("852");
  const [number, setNumber] = useState("");
  const [twoFaCode, setTwoFaCode] = useState("");
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");

  const { getOtpCode, access } = useAuthStore();

  const handleTwoFaCode = () => {
    // mobile
    if (method === 0) {
      if (postCode.length === 0) {
        openWarningNotification(t("login.error.postcodeLength"));
        return;
      }

      if (number.length === 0) {
        openWarningNotification(t("login.error.phoneLength"));
        return;
      }
      // email
    } else {
      if (email.length === 0) {
        openWarningNotification(t("login.error.emailLength"));
        return;
      }

      if (!validateEmail(email)) {
        openWarningNotification(t("login.error.emailFormat"));
        return;
      }
    }

    const requestObj =
      method === 0
        ? {
            countryCode: postCode,
            phoneNum: number,
            type: "phone",
          }
        : {
            email,
            type: "email",
          };
    setLoading(true);
    getOtpCode(requestObj).then((result: any) => {
      setLoading(false);
      const { success } = result;
      if (success)
        return openSuccessNotification(t("login.mobileForm.codeSent"));
    });
  };

  const handleLogin = () => {
    if (twoFaCode.length === 0)
      return openWarningNotification(t("login.error.twoFaLength"));

    const requestObj =
      method === 0
        ? {
            countryCode: postCode,
            phoneNum: number,
            type: "phone",
            otp: twoFaCode,
          }
        : {
            email,
            type: "email",
            otp: twoFaCode,
          };

    setLoading(true);
    access(requestObj).then((result: any) => {
      setLoading(false);
      if (result && result["success"]) {
        openSuccessNotification(t("login.successLogin"));
        return history.push("/");
      }
      return openErrorNotification(t("login.successFailed"));
    });
  };

  return (
    <div className="relative clear-both ">
      <div
        className={`${backgroundImgStyle} w-full bg-cover`}
        style={{
          backgroundImage: `url("/assets/login-bg.png"`,
        }}
      >
        <div className="h-full w-full drop-shadow-lg bg-black bg-opacity-70">
          {loading ? (
            <div className="h-full flex flex-row justify-center items-center">
              <LoadingIcon size={100} />
            </div>
          ) : (
            <div className="h-full max-md:flex max-md:flex-col md:flex md:flex-row">
              {/* introduction */}
              <div className="w-full flex flex-col justify-center md:flex-1 max-md:py-5 md:border-r-[25px] max-md:border-b-[5px] border-primary">
                <h4 className={customTextStyle}>{t(`login.introA`)}</h4>
                <h4 className={customTextStyle}>{t(`login.introB`)}</h4>
                <h4 className={customTextStyle}>{t(`login.introC`)}</h4>
              </div>

              <div className="flex flex-col flex-1 md:px-60 md:mt-48 max-md:px-10 max-md:pt-10">
                {/* login method */}
                <div className="flex flex-row sm:flex-col">
                  <p
                    className={`${loginMethodTextStyle} flex-1 text-sm  ${
                      method === 0 &&
                      "underline underline-offset-8 decoration-[#89FF49]"
                    }`}
                    onClick={() => setMethod(0)}
                  >
                    {t(`login.mobile`)}
                  </p>
                  <p
                    className={`${loginMethodTextStyle} flex-1 text-sm  ${
                      method === 1 &&
                      "underline underline-offset-8 decoration-[#89FF49]"
                    }`}
                    onClick={() => setMethod(1)}
                  >
                    {t(`login.email`)}
                  </p>
                </div>
                {/* login form */}
                <div className="md:w-[72%] max-md:w-full self-center mt-5">
                  {method === 0 ? (
                    <MobileLoginForm
                      postCode={postCode}
                      setPostCode={setPostCode}
                      number={number}
                      setNumber={setNumber}
                      twoFaCode={twoFaCode}
                      setTwoFaCode={setTwoFaCode}
                      handleTwoFaCode={handleTwoFaCode}
                    />
                  ) : (
                    <EmailLoginForm
                      email={email}
                      setEmail={setEmail}
                      twoFaCode={twoFaCode}
                      setTwoFaCode={setTwoFaCode}
                      handleTwoFaCode={handleTwoFaCode}
                    />
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <ReverseButton
        handleAction={() => handleLogin()}
        disabled={loading}
        customStyle="border-0 absolute z-40 bg-primary bottom-[0px] pl-8 px-6 py-4 right-[0px] text-[14px] font-black tracking-wider"
        buttonText="login.login"
      />
    </div>
  );
};

import React, { useEffect, useState } from "react";
import { Container } from "src/components";
import { EventSection } from "./event-section";
import { HotCoupon } from "./hot-coupon";
import { AllCoupons } from "./all-coupons";
import { useCouponStore } from "../..//stores";

export const Home = (props: any) => {
  const [coupon, setCoupon] = useState<any>([]);
  const [eventCoupon, setEventCoupon] = useState<any>([]);
  const [hotCoupon, setHotCoupon] = useState<any>([]);
  const { getCoupons } = useCouponStore();

  useEffect(() => {
    getCoupons(`?type=event`).then((result: any) => {
      if (!result) return setEventCoupon([]);
      setEventCoupon(result);
    });
  }, [getCoupons, setCoupon]);

  useEffect(() => {
    getCoupons(`?is_trending=1`).then((result: any) => {
      if (!result) return setHotCoupon([]);
      setHotCoupon(result);
    });
  }, [getCoupons, setHotCoupon]);

  useEffect(() => {
    getCoupons().then((result: any) => {
      if (!result) return setCoupon([]);
      setCoupon(result);
    });
  }, [getCoupons, setCoupon]);

  return (
    <Container>
      {/* Header Event Section */}
      <EventSection coupon={eventCoupon} />

      {/* Hot Coupon Items */}
      <HotCoupon coupon={hotCoupon} />

      {/* All products*/}
      <AllCoupons coupon={coupon} />
    </Container>
  );
};

import React from "react";
import { t } from "i18next";

export const CouponTnC = (props: any) => {
  const { coupon } = props;

  const handleHtmlText = (text: any) => {
    return { __html: text };
  };

  return (
    <div className="max-w-[690px] sm:w-[690px]">
      {/* Coupon Title */}
      <h4 className="text-black tracking-[18px] pb-[18px]">
        {t(`coupon.tnc`)}
      </h4>
      {/* Coupon Terms & Conditions */}
      <ul className="pl-[24px]">
        {coupon.length > 0 &&
          coupon[0].how_to_use.map((redemption: any, i: number) => {
            return (
              <li
                key={i}
                className="leading-[40px] tracking-[2px] font-bold text-[14px] text-[#6F6F6F] text-left"
                dangerouslySetInnerHTML={handleHtmlText(redemption)}
              ></li>
            );
          })}
      </ul>
    </div>
  );
};
